import React from 'react';

const NotFoundPage = () => (
  <section className="section">
    <div className="container">
      <h1 className="title has-text-centered">NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </section>
);

export default NotFoundPage;
